@keyframes scroll-feedbacks {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(0);
  }
  65% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}
